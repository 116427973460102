import React, { useEffect, useState } from 'react';
import { supabase } from '../supabase';
import { Link, useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [properties, setProperties] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const checkUserSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session && session.user) {
        setUser(session.user);
        fetchFavorites(session.user.id); // Fetch user's favorites
      } else {
        navigate('/login'); // Redirect to login if no session
      }
    };

    const fetchFavorites = async (userId) => {
      const { data: favoritesData, error } = await supabase
        .from('favorites')
        .select('property_id')
        .eq('user_id', userId);

      if (error) {
        console.error('Error fetching favorites:', error);
      } else {
        for (let favorite of favoritesData) {
          const { data: propertyData, error: propertyError } = await supabase
            .from('properties')
            .select('*')
            .eq('id', favorite.property_id)
            .single();

          if (propertyError || !propertyData) {
            await supabase
              .from('favorites')
              .delete()
              .eq('user_id', userId)
              .eq('property_id', favorite.property_id);
          } else {
            setProperties((prevProperties) => [...prevProperties, propertyData]);
          }
        }
      }
    };

    checkUserSession();
  }, [navigate]);

  if (!user) {
    return <div>Loading...</div>;
  }

  const handleRemoveFavorite = async (propertyId) => {
    const { error } = await supabase
      .from('favorites')
      .delete()
      .eq('user_id', user.id)
      .eq('property_id', propertyId);

    if (!error) {
      setProperties(properties.filter((property) => property.id !== propertyId));
    } else {
      alert('Failed to remove property from favorites.');
    }
  };

  return (
    <div>
      <h2>Welcome to your Dashboard, {user.email}!</h2>
      
      <h3>Your Saved Properties:</h3>
      <ul>
        {properties.length > 0 ? (
          properties.map((property) => (
            <li key={property.id}>
              <Link to={`/property/${property.id}`}>{property.title}</Link> - {property.city}, {property.region}
              <button onClick={() => handleRemoveFavorite(property.id)}>Remove</button>
            </li>
          ))
        ) : (
          <li>No saved properties found.</li>
        )}
      </ul>

      <button onClick={() => supabase.auth.signOut().then(() => navigate('/login'))}>
        Log Out
      </button>
    </div>
  );
};

export default Dashboard;