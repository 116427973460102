import React, { useState } from 'react';
import { supabase } from '../supabase';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    setError('');  // Clear any previous error

    const { error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

    if (error) {
      setError(`Error logging in: ${error.message}`);
    } else {
      // Fetch the session and ensure it's saved
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        console.log('User logged in:', session.user);
        navigate('/dashboard');  // Redirect to dashboard
      } else {
        setError('Login failed. No session found.');
      }
    }
  };

  return (
    <div>
      <h2>Login</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleLogin}>Login</button>
    </div>
  );
};

export default Login;