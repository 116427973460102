import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { supabase } from '../supabase';
import { Button, Grid, Card, CardContent, Typography, TextField, MenuItem, Collapse, Slider, CardMedia, Chip, Container } from '@mui/material';
import { Bed as BedIcon, Bathtub as BathtubIcon, DirectionsCar as DirectionsCarIcon } from '@mui/icons-material';
import { useLocation, Link } from 'react-router-dom';

const PropertiesList = () => {
  const [properties, setProperties] = useState([]);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [limit, setLimit] = useState(50);
  const location = useLocation();

  const [filters, setFilters] = useState({
    city: '',
    minPrice: 0,
    maxPrice: '',
    bedrooms: '',
    propertyType: '',
    sortBy: ''
  });

  useEffect(() => {
    const fetchProperties = async () => {
      let query = supabase.from('properties').select('*').limit(limit);

      const params = new URLSearchParams(location.search);
      const searchQuery = params.get('query');

      if (searchQuery) {
        query = query.ilike('city', `%${searchQuery}%`).or(`region.ilike.%${searchQuery}%`);
      }

      if (filters.city) query = query.eq('city', filters.city);
      if (filters.minPrice) query = query.gte('price', filters.minPrice);
      if (filters.maxPrice !== '') query = query.lte('price', filters.maxPrice);
      if (filters.bedrooms) query = query.eq('rooms', filters.bedrooms);
      if (filters.propertyType) query = query.eq('type', filters.propertyType);

      if (filters.sortBy === 'price-asc') {
        query = query.order('price', { ascending: true });
      } else if (filters.sortBy === 'price-desc') {
        query = query.order('price', { ascending: false });
      }

      const { data, error } = await query;
      if (error) console.error('Error fetching properties:', error);
      else setProperties(data);
    };

    fetchProperties();
  }, [location.search, filters, limit]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handlePriceChange = (event, newValue) => {
    setFilters({ ...filters, minPrice: newValue[0], maxPrice: newValue[1] === 10000000 ? '' : newValue[1] });
  };

  const handleLoadMore = () => {
    setLimit(limit + 50);
  };

  const getFirstImage = (property) => {
    let pictures = [];
    if (property.pictures) {
      if (typeof property.pictures === 'string') {
        try {
          pictures = JSON.parse(property.pictures);
        } catch {
          pictures = [property.pictures];
        }
      } else if (Array.isArray(property.pictures)) {
        pictures = property.pictures;
      }
    }
    return pictures.length > 0 ? (typeof pictures[0] === 'string' ? pictures[0] : pictures[0].picture_url) : 'placeholder-image-url';
  };

  const formatPrice = (price) => {
    return price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'N/A';
  };

  const getLabelColor = (type) => {
    return type === 'For Sale' ? '#2e7d32' : '#1976d2';
  };

  return (
    <div>
      <Helmet>
        <title>Browse Properties | CARISINILA MALAYSIA</title>
        <meta name="description" content="Browse properties available for sale or rent on CARISINILA MALAYSIA." />
      </Helmet>

      {/* Enhanced Property Listings Title */}
      <Container maxWidth="lg" style={{ textAlign: 'center', marginTop: '30px', marginBottom: '20px' }}>
        <Typography variant="h4" style={{ fontWeight: 'bold', color: '#1976d2' }}>
          Property Listings
        </Typography>
        {/* Optional Divider */}
        <div style={{ width: '80px', height: '4px', backgroundColor: '#1976d2', margin: '10px auto' }}></div>
        <Typography variant="body1" color="textSecondary" style={{ marginBottom: '10px' }}>
          Discover the latest properties available for sale or rent.
        </Typography>
      </Container>

      <Button variant="outlined" onClick={() => setFiltersVisible(!filtersVisible)}>
        {filtersVisible ? 'Hide Filters' : 'Show Filters'}
      </Button>

      <Collapse in={filtersVisible} timeout="auto" unmountOnExit>
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          <Grid item xs={12} sm={4}>
            <TextField
              select
              label="City"
              name="city"
              value={filters.city}
              onChange={handleFilterChange}
              fullWidth
            >
               <MenuItem value="">All Cities</MenuItem>
<MenuItem value="Johor">Johor</MenuItem>
<MenuItem value="Kedah">Kedah</MenuItem>
<MenuItem value="Kelantan">Kelantan</MenuItem>
<MenuItem value="Melaka">Melaka</MenuItem>
<MenuItem value="Negeri Sembilan">Negeri Sembilan</MenuItem>
<MenuItem value="Pahang">Pahang</MenuItem>
<MenuItem value="Penang">Penang</MenuItem>
<MenuItem value="Perak">Perak</MenuItem>
<MenuItem value="Perlis">Perlis</MenuItem>
<MenuItem value="Sabah">Sabah</MenuItem>
<MenuItem value="Sarawak">Sarawak</MenuItem>
<MenuItem value="Selangor">Selangor</MenuItem>
<MenuItem value="Terengganu">Terengganu</MenuItem>
<MenuItem value="Kuala Lumpur">Kuala Lumpur</MenuItem>
<MenuItem value="Labuan">Labuan</MenuItem>
<MenuItem value="Putrajaya">Putrajaya</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              select
              label="Bedrooms"
              name="bedrooms"
              value={filters.bedrooms}
              onChange={handleFilterChange}
              fullWidth
            >
              <MenuItem value="">Any</MenuItem>
              <MenuItem value="1">1 Bedroom</MenuItem>
              <MenuItem value="2">2 Bedrooms</MenuItem>
              <MenuItem value="3">3 Bedrooms</MenuItem>
              <MenuItem value="4">4+ Bedrooms</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              select
              label="Property Type"
              name="propertyType"
              value={filters.propertyType}
              onChange={handleFilterChange}
              fullWidth
            >
              <MenuItem value="">All Types</MenuItem>
              <MenuItem value="Apartment">Apartment</MenuItem>
              <MenuItem value="House">House</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Slider
              value={[filters.minPrice || 0, filters.maxPrice || 10000000]}
              onChange={handlePriceChange}
              valueLabelDisplay="auto"
              min={0}
              max={10000000}
              step={50000}
              marks={[
                { value: 0, label: '0' },
                { value: 10000000, label: 'No Max' },
              ]}
            />
            <Typography>Price Range: RM {filters.minPrice} - {filters.maxPrice ? `RM ${filters.maxPrice}` : 'No Max'}</Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              select
              label="Sort By"
              name="sortBy"
              value={filters.sortBy}
              onChange={handleFilterChange}
              fullWidth
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="price-asc">Price: Low to High</MenuItem>
              <MenuItem value="price-desc">Price: High to Low</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Collapse>

      {/* Property Cards with Improved UI/UX */}
      <Container maxWidth="lg" style={{ marginTop: '20px' }}>
        <Grid container spacing={3}>
          {properties.map((property) => (
            <Grid item xs={12} sm={6} md={4} key={property.id}>
              <Card style={{ display: 'flex', flexDirection: 'column', height: '100%', boxShadow: '0px 4px 8px rgba(0,0,0,0.1)' }}>
                <CardMedia
                  component="img"
                  height="200"
                  image={getFirstImage(property)}
                  alt={property.title}
                  style={{ objectFit: 'cover' }}
                />

                {/* Card Header with a subtle background */}
                <CardContent style={{ flexGrow: 1, backgroundColor: '#f5f5f5' }}>
                  <Typography variant="h6" gutterBottom style={{ height: '3em', overflow: 'hidden' }}>
                    {property.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" style={{ height: '2em' }}>
                    {property.city}, {property.region}
                  </Typography>

                  {/* For Sale/For Rent Chip */}
                  <Chip
                    label={property.type}
                    style={{ backgroundColor: getLabelColor(property.type), color: 'white', marginTop: '10px', fontSize: '0.9rem' }}
                  />

                  {/* Property details with icons */}
                  <Grid container spacing={1} style={{ marginTop: '10px' }}>
                    <Grid item>
                      <Typography variant="body2">
                        <BedIcon style={{ marginRight: '5px' }} />
                        {property.rooms} Bedrooms
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        <BathtubIcon style={{ marginRight: '5px' }} />
                        {property.bathrooms} Bathrooms
                      </Typography>
                    </Grid>
                    {property.parking && (
                      <Grid item>
                        <Typography variant="body2">
                          <DirectionsCarIcon style={{ marginRight: '5px' }} />
                          {property.parking} Parking
                        </Typography>
                      </Grid>
                    )}
                  </Grid>

                  <Typography variant="body2" style={{ color: getLabelColor(property.type), fontWeight: 'bold', marginTop: '10px' }}>
                    Price: RM {formatPrice(property.price)}
                  </Typography>
                </CardContent>

                <div style={{ padding: '10px' }}>
                  <Button
                    component={Link}
                    to={`/property/${property.id}`}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    View Details
                  </Button>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>

        {properties.length >= limit && (
          <Button variant="contained" color="primary" onClick={handleLoadMore} style={{ marginTop: '20px' }}>
            Load More
          </Button>
        )}
      </Container>
    </div>
  );
};

export default PropertiesList;