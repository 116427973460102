import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { supabase } from '../supabase';

const Header = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkUserSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setUser(session ? session.user : null);
    };

    checkUserSession();
  }, []);

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setUser(null);
    navigate('/login');
  };

  return (
    <AppBar position="static" color="primary" style={{ boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)' }}>
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* Company Name */}
        <Typography 
          variant="h6" 
          component={Link} 
          to="/" 
          style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold' }}
        >
          Carisinila Malaysia
        </Typography>

        {/* Navigation Links */}
        <Box>
          <Button 
            color="inherit" 
            component={Link} 
            to="/" 
            style={{ marginRight: '10px', transition: '0.3s' }}
            onMouseEnter={e => e.target.style.color = '#ffcc00'}
            onMouseLeave={e => e.target.style.color = 'white'}
          >
            Home
          </Button>
          <Button 
            color="inherit" 
            component={Link} 
            to="/properties" 
            style={{ marginRight: '10px', transition: '0.3s' }}
            onMouseEnter={e => e.target.style.color = '#ffcc00'}
            onMouseLeave={e => e.target.style.color = 'white'}
          >
            Properties
          </Button>
          <Button 
            color="inherit" 
            component={Link} 
            to="/mortgage-calculator"  // Added Mortgage Calculator link here
            style={{ marginRight: '10px', transition: '0.3s' }}
            onMouseEnter={e => e.target.style.color = '#ffcc00'}
            onMouseLeave={e => e.target.style.color = 'white'}
          >
            Mortgage Calculator
          </Button>

          {user ? (
            <>
              <Button 
                color="inherit" 
                component={Link} 
                to="/dashboard" 
                style={{ marginRight: '10px', transition: '0.3s' }}
                onMouseEnter={e => e.target.style.color = '#ffcc00'}
                onMouseLeave={e => e.target.style.color = 'white'}
              >
                Dashboard
              </Button>
              <Button color="inherit" onClick={handleLogout}>
                Log Out
              </Button>
            </>
          ) : (
            <>
              <Button 
                color="inherit" 
                component={Link} 
                to="/signup" 
                style={{ marginRight: '10px', transition: '0.3s' }}
                onMouseEnter={e => e.target.style.color = '#ffcc00'}
                onMouseLeave={e => e.target.style.color = 'white'}
              >
                Sign Up
              </Button>
              <Button color="inherit" component={Link} to="/login">
                Login
              </Button>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;