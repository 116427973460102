import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Typography, Grid, MenuItem, Card, CardContent, Chip, Box } from '@mui/material';
import { Helmet } from 'react-helmet';

// Dummy data for news
const newsData = [
  {
    title: 'Malaysia Real Estate Market 2024 Predictions',
    source: 'The Star',
    link: 'https://www.thestar.com.my/news/real-estate-market-2024',
    date: 'October 8, 2024',
  },
  {
    title: 'Property Investment Tips for 2024',
    source: 'Google News',
    link: 'https://news.google.com/property-investment-2024',
    date: 'October 7, 2024',
  },
  {
    title: 'How the Malaysian Economy Impacts Real Estate Prices',
    source: 'China Press',
    link: 'https://www.chinapress.com.my/news/real-estate-economy',
    date: 'October 6, 2024',
  },
];

const LandingPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [city, setCity] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const navigate = useNavigate();

  const handleSearch = () => {
    const queryParams = new URLSearchParams();
    if (searchQuery) queryParams.append('query', searchQuery);
    if (city) queryParams.append('city', city);
    if (propertyType) queryParams.append('type', propertyType);

    navigate(`/search?${queryParams.toString()}`);
  };

  return (
    <div>
      {/* Helmet for SEO */}
      <Helmet>
        <title>Carisinila Malaysia | Find Your Dream Property</title>
        <meta name="description" content="Find the best properties for sale or rent in Malaysia. Search by city, region, and property type across 14 states." />
        <meta name="keywords" content="Malaysia real estate, properties for sale, properties for rent, property listings, Malaysia property portal" />
        <meta property="og:title" content="Carisinila Malaysia | Find Your Dream Property" />
        <meta property="og:description" content="Explore top properties across Malaysia. Search by city, region, and property type." />
        <meta property="og:url" content="https://www.carisinila.com" />
        <meta property="og:type" content="website" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      {/* Hero Section */}
      <div style={{
        padding: '100px 0',
        textAlign: 'center',
        backgroundImage: 'url(/heromain1.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        color: '#fff',
        marginBottom: '50px',
      }}>
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }} />
        <Container maxWidth="md" style={{ position: 'relative', zIndex: 2 }}>
          <Typography variant="h2" gutterBottom style={{ fontWeight: 'bold', fontSize: '3rem' }}>
            Find Your Dream Property in Malaysia
          </Typography>
          <Typography variant="h6" gutterBottom style={{ fontSize: '1.25rem' }}>
            Browse properties across 14 states and top cities
          </Typography>

          {/* Search Filters */}
          <Grid container spacing={2} justifyContent="center" style={{ marginBottom: '40px' }}>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                placeholder="Search by City or Region"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                select
                label="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                fullWidth
              >
                <MenuItem value="">All Cities</MenuItem>
                {/* Add all cities */}
                <MenuItem value="Selangor">Selangor</MenuItem>
                <MenuItem value="Kuala Lumpur">Kuala Lumpur</MenuItem>
                <MenuItem value="Penang">Penang</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                select
                label="For Sale / For Rent"
                value={propertyType}
                onChange={(e) => setPropertyType(e.target.value)}
                fullWidth
              >
                <MenuItem value="sale">For Sale</MenuItem>
                <MenuItem value="rent">For Rent</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button variant="contained" color="primary" onClick={handleSearch} fullWidth>
                Search
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>

      {/* Recommended Properties by State Section */}
      <Container maxWidth="lg">
        <Box textAlign="center" mb={5}>
          <Typography variant="h4" style={{ fontWeight: 'bold', color: '#1976d2' }}>
            Recommended Properties by State
          </Typography>
          <div style={{
            width: '60px',
            height: '4px',
            backgroundColor: '#1976d2',
            margin: '10px auto 30px',
          }}></div>
        </Box>

        <Grid container spacing={3}>
          {[
            { state: 'Selangor', type: 'For Sale', city: 'Selangor', label: 'For Sale' },
            { state: 'Kuala Lumpur', type: 'For Rent', city: 'Kuala Lumpur', label: 'For Rent' },
            { state: 'Penang', type: 'For Sale', city: 'Penang', label: 'For Sale' },
            { state: 'Johor', type: 'For Rent', city: 'Johor', label: 'For Rent' },
          ].map((property, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: '0.3s',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              }}
                onMouseEnter={e => e.currentTarget.style.transform = 'scale(1.03)'}
                onMouseLeave={e => e.currentTarget.style.transform = 'scale(1)'}
              >
                <CardContent style={{ flexGrow: 1 }}>
                  <Box display="flex" justifyContent="space-between" alignItems="center" style={{ marginBottom: '15px' }}>
                    <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
                      {property.state} Properties
                    </Typography>
                    <Chip label={property.label} color="primary" />
                  </Box>
                  <Typography variant="body2" color="textSecondary" paragraph>
                    Explore the top properties in {property.state}.
                  </Typography>
                </CardContent>
                <Box p={2} pt={0}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => navigate(`/search?city=${property.city}&type=${property.type.toLowerCase()}`)}
                  >
                    View Properties
                  </Button>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Latest News & Market Insights Section */}
      <Container maxWidth="lg" style={{ marginTop: '60px' }}>
        <Box textAlign="center" mb={5}>
          <Typography variant="h4" style={{ fontWeight: 'bold', color: '#1976d2' }}>
            Latest News & Market Insights
          </Typography>
          <div style={{
            width: '60px',
            height: '4px',
            backgroundColor: '#1976d2',
            margin: '10px auto 30px',
          }}></div>
        </Box>

        <Grid container spacing={3}>
          {newsData.map((newsItem, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card style={{
                transition: '0.3s',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              }}
                onMouseEnter={e => e.currentTarget.style.transform = 'scale(1.03)'}
                onMouseLeave={e => e.currentTarget.style.transform = 'scale(1)'}
              >
                <CardContent>
                  <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
                    {newsItem.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px' }}>
                    Source: {newsItem.source} <br />
                    Published on: {newsItem.date}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    href={newsItem.link}
                    target="_blank"
                    fullWidth
                    style={{ padding: '10px' }}
                  >
                    Read More
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default LandingPage;