import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Grid, Card, CardContent, Box, InputAdornment } from '@mui/material';
import { Helmet } from 'react-helmet';

const MortgageCalculator = () => {
  const [loanAmount, setLoanAmount] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [loanTenure, setLoanTenure] = useState('');
  const [monthlyPayment, setMonthlyPayment] = useState(null);

  // Format number with commas (for displaying purposes, not live input)
  const formatNumber = (value) => {
    return value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  // Handle loan amount input (allow natural input)
  const handleLoanAmountChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, ''); // Allow only numbers and dots
    setLoanAmount(value);
  };

  // Handle interest rate input (allow only valid decimal percentages)
  const handleInterestRateChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, ''); // Allow only numbers and dots
    setInterestRate(value);
  };

  // Calculate mortgage payment
  const calculateMortgage = () => {
    const principal = parseFloat(loanAmount.replace(/,/g, '')); // Convert string to number, removing commas
    const annualInterest = parseFloat(interestRate) / 100;
    const tenureMonths = parseInt(loanTenure) * 12;
    const monthlyInterest = annualInterest / 12;
    const payment = (principal * monthlyInterest) / (1 - Math.pow(1 + monthlyInterest, -tenureMonths));
    setMonthlyPayment(payment ? formatNumber(payment) : 'Invalid');
  };

  return (
    <div>
      {/* SEO Optimization */}
      <Helmet>
        <title>Malaysia Mortgage Calculator | Carisinila</title>
        <meta name="description" content="Calculate your monthly mortgage payments with Carisinila's mortgage calculator. Get the latest news and insights on Malaysian mortgage policies." />
      </Helmet>

      {/* Hero Section with Mortgage Calculator */}
      <div style={{ padding: '80px 0', backgroundColor: '#f9f9f9', textAlign: 'center' }}>
        <Container maxWidth="md">
          <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', color: '#1976d2' }}>
            Malaysia Mortgage Calculator
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '40px' }}>
            Calculate your monthly mortgage payments and discover your loan eligibility.
          </Typography>

          {/* Mortgage Calculator Form */}
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={4}>
              <TextField
                label="Loan Amount (RM)"
                variant="outlined"
                fullWidth
                value={loanAmount}
                onChange={handleLoanAmountChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start">RM</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Interest Rate"
                variant="outlined"
                fullWidth
                value={interestRate}
                onChange={handleInterestRateChange}
                InputProps={{
                  endAdornment: <InputAdornment position="end">% per annum</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Loan Tenure (Years)"
                variant="outlined"
                fullWidth
                value={loanTenure}
                onChange={(e) => setLoanTenure(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={calculateMortgage}
              >
                Calculate
              </Button>
            </Grid>
          </Grid>

          {/* Display Monthly Payment */}
          {monthlyPayment && (
            <Typography variant="h5" style={{ marginTop: '20px', fontWeight: 'bold', color: '#1976d2' }}>
              Monthly Payment: RM {monthlyPayment}
            </Typography>
          )}
        </Container>
      </div>

      {/* Informational Section on Mortgage */}
      <Container maxWidth="lg" style={{ marginTop: '60px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', color: '#1976d2' }}>
              Malaysian Mortgage Policies
            </Typography>
            <Typography variant="body1" paragraph>
              The Malaysian mortgage landscape offers flexible terms for home buyers, including both fixed and floating interest rates. Most banks offer tenures of up to 35 years, and the loan amount you can secure depends on factors like your credit score, income, and down payment.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => alert('Contact us for more details')}
            >
              Contact for Mortgage Consultation
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', color: '#1976d2' }}>
              Loan Eligibility
            </Typography>
            <Typography variant="body1" paragraph>
              You can secure up to 90% of the property's value through home loans in Malaysia. Key factors determining eligibility include your credit score, monthly income, and existing financial commitments. Bank Negara Malaysia regulates these loans, ensuring competitive rates for buyers.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => alert('Get Pre-Approved Now')}
            >
              Get Pre-Approved for a Loan
            </Button>
          </Grid>
        </Grid>
      </Container>

      {/* News and Insights Section */}
      <Container maxWidth="lg" style={{ marginTop: '60px' }}>
        <Typography variant="h4" align="center" style={{ fontWeight: 'bold', color: '#1976d2', marginBottom: '30px' }}>
          Latest News & Market Insights
        </Typography>
        <Grid container spacing={3}>
          {[
            { title: 'How Interest Rates Affect Home Loans', source: 'The Star', link: '#', date: 'Oct 2024' },
            { title: 'Tips for First-Time Home Buyers in Malaysia', source: 'Edge Prop', link: '#', date: 'Oct 2024' },
            { title: 'Understanding Malaysia’s Mortgage Market', source: 'NST', link: '#', date: 'Oct 2024' },
          ].map((news, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                    {news.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px' }}>
                    Source: {news.source} <br />
                    Date: {news.date}
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    href={news.link}
                    target="_blank"
                  >
                    Read More
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Call to Action Section */}
      <Container maxWidth="lg" style={{ marginTop: '60px', padding: '40px', backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
        <Box textAlign="center">
          <Typography variant="h4" style={{ fontWeight: 'bold', color: '#1976d2', marginBottom: '20px' }}>
            Ready to Find the Perfect Mortgage?
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => alert('Start the Application Process')}
          >
            Start Your Mortgage Application
          </Button>
        </Box>
      </Container>
    </div>
  );
};

export default MortgageCalculator;