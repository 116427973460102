import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import PropertiesList from './components/PropertiesList';
import PropertyDetail from './components/PropertyDetail';
import Signup from './components/Signup';
import Login from './components/Login';
import Header from './components/Header';
import Footer from './components/Footer';
import Dashboard from './components/Dashboard';
import MortgageCalculator from './components/MortgageCalculator';

const App = () => {
  useEffect(() => {
    // Load Google Analytics script
    const gaScript = document.createElement('script');
    gaScript.src = `https://www.googletagmanager.com/gtag/js?id=G-99P3BYGW50`;
    gaScript.async = true;
    document.head.appendChild(gaScript);

    // Initialize Google Analytics
    gaScript.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-99P3BYGW50');
    };

    // Load Google AdSense script
    const adsenseScript = document.createElement('script');
    adsenseScript.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5038584083200880";
    adsenseScript.crossOrigin = "anonymous";
    adsenseScript.async = true;
    document.head.appendChild(adsenseScript);

    return () => {
      // Clean up scripts on unmount if necessary
      document.head.removeChild(gaScript);
      document.head.removeChild(adsenseScript);
    };
  }, []);

  return (
    <Router>
      <Header />
      <div style={{ minHeight: 'calc(100vh - 150px)' }}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/properties" element={<PropertiesList />} />
          <Route path="/property/:id" element={<PropertyDetail />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/search" element={<PropertiesList />} />
          <Route path="/dashboard" element={<Dashboard />} />  
          <Route path="/mortgage-calculator" element={<MortgageCalculator />} /> {/* New route */}
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default App;